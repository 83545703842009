import React from 'react';
import { Link, useLocation } from 'react-router-dom';

// Admin section mapping for breadcrumb navigation
export const ADMIN_SECTIONS = {
  'virtual-internships': {
    title: 'Virtual Internships',
    listPath: '/admin/virtual-internships',
    pages: {
      'new': 'Create New Internship',
      'edit': 'Edit Internship',
      'locations': 'Locations',
      'activities': 'Activities',
      'hotspots': 'Hotspots',
      'assets': 'Assets',
      'analytics': 'Analytics'
    }
  },
  'courses': {
    title: 'Courses',
    listPath: '/admin/courses',
    pages: {
      'new': 'Create New Course',
      'edit': 'Edit Course',
      'lessons': 'Lessons'
    }
  },
  'careers': {
    title: 'Careers',
    listPath: '/admin/careers',
    pages: {
      'new': 'Create New Career',
      'edit': 'Edit Career'
    }
  },
  'jobs': {
    title: 'Jobs',
    listPath: '/admin/jobs',
    pages: {
      'new': 'Create New Job',
      'edit': 'Edit Job',
      'upload': 'Upload Jobs'
    }
  },
  'companies': {
    title: 'Companies',
    listPath: '/admin/companies',
    pages: {
      'new': 'Create New Company',
      'edit': 'Edit Company'
    }
  },
  'cities': {
    title: 'Cities',
    listPath: '/admin/cities',
    pages: {
      'new': 'Create New City',
      'edit': 'Edit City'
    }
  },
  'users': {
    title: 'Users',
    listPath: '/admin/users',
    pages: {
      'new': 'Create New User',
      'edit': 'Edit User'
    }
  },
  'industries': {
    title: 'Industries',
    listPath: '/admin/industries',
    pages: {
      'new': 'Create New Industry',
      'edit': 'Edit Industry'
    }
  },
  'portals': {
    title: 'Portals',
    listPath: '/admin/portals',
    pages: {
      'new': 'Create New Portal',
      'edit': 'Edit Portal'
    }
  },
  'pages': {
    title: 'Pages',
    listPath: '/admin/pages',
    pages: {
      'new': 'Create New Page',
      'edit': 'Edit Page'
    }
  },
  'partners': {
    title: 'Partners',
    listPath: '/admin/partners',
    pages: {
      'new': 'Create New Partner',
      'edit': 'Edit Partner'
    }
  },
  'badges': {
    title: 'Badges',
    listPath: '/admin/badges',
    pages: {
      'new': 'Create New Badge',
      'edit': 'Edit Badge'
    }
  },
  'badge-assertions': {
    title: 'Badge Assertions',
    listPath: '/admin/badge-assertions',
    pages: {
      'view': 'View Assertion',
      'issue': 'Issue Badge'
    }
  },
  'collections': {
    title: 'Collections',
    listPath: '/admin/collections',
    pages: {
      'new': 'Create New Collection',
      'edit': 'Edit Collection'
    }
  },
  'course-generator': {
    title: 'Course Generator',
    listPath: '/admin/course-generator',
    pages: {}
  }
};

const AdminBreadcrumb = () => {
  const location = useLocation();
  const pathSegments = location.pathname.split('/').filter(Boolean);
  
  // Only show breadcrumb for admin routes
  if (!pathSegments.includes('admin')) return null;
  
  // Extract the relevant path segments
  const adminIndex = pathSegments.indexOf('admin');
  const relevantSegments = pathSegments.slice(adminIndex);
  
  // If we're just at the dashboard, show a simplified breadcrumb
  if (relevantSegments.length <= 2 && relevantSegments[1] === 'dashboard') {
    return (
      <nav aria-label="breadcrumb" className="bg-light py-2">
        <div className="container">
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item active">Admin Dashboard</li>
          </ol>
        </div>
      </nav>
    );
  }
  
  // Determine the section and page
  const sectionKey = relevantSegments[1];
  const section = ADMIN_SECTIONS[sectionKey];
  
  // If section doesn't exist in our mapping, fall back to basic breadcrumb
  if (!section) {
    return (
      <nav aria-label="breadcrumb" className="bg-light py-2">
        <div className="container">
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <Link to="/admin/dashboard">Admin Dashboard</Link>
            </li>
            {relevantSegments.length > 1 && (
              <li className="breadcrumb-item active">
                {relevantSegments[1].charAt(0).toUpperCase() + relevantSegments[1].slice(1).replace(/-/g, ' ')}
              </li>
            )}
          </ol>
        </div>
      </nav>
    );
  }
  
  // Check if we're at a detail page (3rd level)
  let isDetailPage = relevantSegments.length > 2;
  let detailPageKey = relevantSegments[2];
  let detailPageTitle = '';
  let detailId = '';
  
  // Handle special case for edit pages where the ID is in the URL
  if (detailPageKey === 'edit' && relevantSegments.length > 3) {
    detailId = relevantSegments[3];
    detailPageTitle = section.pages['edit'] || 'Edit';
  } 
  // Handle other detail pages
  else if (section.pages[detailPageKey]) {
    detailPageTitle = section.pages[detailPageKey];
  } 
  // If it's a numeric ID, it's likely a detail view
  else if (!isNaN(detailPageKey)) {
    detailId = detailPageKey;
    detailPageTitle = 'View Details';
    
    // Check for sub-resources (4th level)
    if (relevantSegments.length > 3 && section.pages[relevantSegments[3]]) {
      detailPageTitle = section.pages[relevantSegments[3]];
    }
  }
  
  return (
    <nav aria-label="breadcrumb" className="bg-light py-2">
      <div className="container">
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item">
            <Link to="/admin/dashboard">Admin Dashboard</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={section.listPath}>{section.title}</Link>
          </li>
          {isDetailPage && (
            <li className="breadcrumb-item active">
              {detailPageTitle} {detailId ? `#${detailId}` : ''}
            </li>
          )}
        </ol>
      </div>
    </nav>
  );
};

export default AdminBreadcrumb;