import React from 'react';
import { useNavigate } from 'react-router-dom';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      hasError: false, 
      error: null, 
      errorInfo: null,
      isDataFetchError: false
    };
    this.handleRetry = this.handleRetry.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
    this.handleGoHome = this.handleGoHome.bind(this);
  }

  static getDerivedStateFromError(error) {
    // Detect if this is a data fetching error
    const isDataFetchError = error && (
      error.name === 'ChunkLoadError' || 
      error.message?.includes('loading chunk') ||
      error.message?.includes('fetch') ||
      error.message?.includes('network')
    );
    
    return { 
      hasError: true,
      isDataFetchError
    };
  }

  componentDidCatch(error, errorInfo) {
    // Capture and log the error details
    console.error('Error caught by boundary:', error, errorInfo);
    this.setState({
      error: error,
      errorInfo: errorInfo
    });
    
    // Report to an error tracking service if available
    if (window._mtm) {
      window._mtm.push({
        'event': 'error',
        'errorType': error.name,
        'errorMessage': error.message,
        'errorStack': error.stack,
        'errorComponent': errorInfo.componentStack
      });
    }
  }
  
  // Reset the error state to allow the component to try rendering again
  handleRetry() {
    this.setState({ 
      hasError: false, 
      error: null, 
      errorInfo: null,
      isDataFetchError: false
    });
  }
  
  // Navigate back in history
  handleGoBack() {
    if (window.history && window.history.length > 1) {
      window.history.back();
    } else {
      window.location.href = '/';
    }
  }
  
  // Go to home page
  handleGoHome() {
    window.location.href = '/';
  }

  render() {
    const { hasError, error, errorInfo, isDataFetchError } = this.state;
    const { fallback } = this.props;
    
    if (hasError) {
      // Use custom fallback if provided
      if (fallback) {
        return fallback({
          error, 
          errorInfo, 
          reset: this.handleRetry
        });
      }
      
      return (
        <div className="container mt-5">
          <div className="alert alert-danger">
            <h2>
              {isDataFetchError 
                ? 'Unable to load content' 
                : 'Something went wrong'}
            </h2>
            <p>
              {isDataFetchError
                ? 'There was a problem loading the content. This could be due to a network issue.'
                : 'An unexpected error occurred while rendering this page.'}
            </p>
            
            <div className="d-flex mt-3 gap-2">
              <button 
                className="btn btn-primary" 
                onClick={this.handleRetry}
              >
                Try Again
              </button>
              
              <button 
                className="btn btn-secondary" 
                onClick={this.handleGoBack}
              >
                Go Back
              </button>
              
              <button 
                className="btn btn-outline-primary" 
                onClick={this.handleGoHome}
              >
                Go to Home
              </button>
            </div>
            
            <details className="mt-4">
              <summary>Error Details</summary>
              <div className="mt-2 bg-light p-3 rounded">
                <p>{error && error.toString()}</p>
                {errorInfo && (
                  <pre style={{ whiteSpace: 'pre-wrap', fontSize: '0.8rem' }}>
                    {errorInfo.componentStack}
                  </pre>
                )}
              </div>
            </details>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
